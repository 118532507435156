import debounce from 'debounce'; // eslint-disable-line import/no-extraneous-dependencies
import autosize from 'autosize';
import parsePhoneNumber from 'libphonenumber-js'; // eslint-disable-line import/no-extraneous-dependencies

export default class FrontEndUtils {
  init() {
    this.mobileMenu();
    this.footer();
    this.generatePhoneNumLinks();
  }

  mobileMenu() {
    const btn = document.querySelector('#toggle-primary-menu');
    const navWrapper = btn.nextElementSibling;
    const openMainNavText = 'Open Main Navigation';
    const closeMainNavText = 'Close Main Navigation';

    // Click events
    btn.addEventListener('click', (e) => {
      const that = e.currentTarget;
      that.classList.toggle('active');
      navWrapper.classList.toggle('active');

      if (that.classList.contains('active')) {
        that.setAttribute('aria-label', closeMainNavText);
        that.setAttribute('aria-expanded', true);
        navWrapper.removeAttribute('aria-hidden');

        return;
      }

      that.setAttribute('aria-label', openMainNavText);
      that.setAttribute('aria-expanded', false);
      navWrapper.setAttribute('aria-hidden', true);
    });

    // Window resize events
    function updateMenu() {
      const width = window.innerWidth;
      if (width >= 1200) {
        btn.classList.remove('active');
        btn.setAttribute('aria-label', openMainNavText);
        btn.setAttribute('aria-expanded', false);
        navWrapper.removeAttribute('aria-hidden');
        navWrapper.classList.remove('active');
      }
    }

    window.onresize = debounce(updateMenu, 300);
  }

  footer() {
    const footerTextAreas = document.querySelectorAll('footer textarea');
    if (!footerTextAreas.length) return;

    autosize(footerTextAreas);
  }

  generatePhoneNumLinks() {
    const phoneNumLinks = document.querySelectorAll('footer .phone-num-link');
    if (!phoneNumLinks.length) return;

    phoneNumLinks.forEach((elem) => {
      const phoneNum = elem.textContent.trim();
      const phoneNumObj = parsePhoneNumber(phoneNum, 'US');

      if (typeof phoneNumObj === 'object') {
        const phoneNumLinkText = phoneNumObj.number;
        elem.setAttribute('href', `tel:${phoneNumLinkText}`);
      }
    });
  }
}
