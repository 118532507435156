import debounce from 'debounce'; // eslint-disable-line import/no-extraneous-dependencies

// JS for WordPress blocks
export default class Blocks {
  init() {
    this.pressBlock();
  }

  // Slideshow for "Press" block
  pressBlock() {
    const pressBlocks = document.querySelectorAll('.press-block');
    if (pressBlocks.length === 0) return;

    pressBlocks.forEach((block) => {
      const slider = block.querySelector('.press-block-slides-wrapper');
      const slides = slider.querySelectorAll('.press-block-slide');
      const lastSlide = slider.querySelector('.press-block-slide-last');
      const prevButton = document.querySelector('.press-block-prev');
      const nextButton = document.querySelector('.press-block-next');

      const sliderWidth = slider.clientWidth;
      const lastSlideWidth = lastSlide.clientWidth;
      const lastSlideMarginRight = sliderWidth - lastSlideWidth;

      let currentSlide = 0;

      function updateStylesLastSlide() {
        const slider2 = block.getElementsByClassName(
          'press-block-slides-wrapper'
        )[0];
        const lastSlide2 = block.getElementsByClassName(
          'press-block-slide-last'
        )[0];
        const sliderWidth2 = slider2.clientWidth;
        const lastSlideWidth2 = lastSlide2.clientWidth;
        const lastSlideMarginRight2 = sliderWidth2 - lastSlideWidth2;

        lastSlide2.style.marginRight = `${lastSlideMarginRight2}px`;
        currentSlide = currentSlide > 0 ? currentSlide - 1 : slides.length - 1;
        scrollToSlide(currentSlide);
      }

      function updateActiveSlide(index) {
        slides.forEach((slide, idx) => {
          slide.classList.toggle('active', idx === index);
        });
      }

      function scrollToSlide(index) {
        const targetSlide = slides[index];
        slider.scrollTo({
          left: targetSlide.offsetLeft - slider.offsetLeft,
          behavior: 'smooth'
        });
        updateActiveSlide(index);
      }

      // Add margin-right to last slide on load and resize
      lastSlide.style.marginRight = `${lastSlideMarginRight}px`;
      window.onresize = debounce(updateStylesLastSlide, 200);

      prevButton.addEventListener('click', () => {
        currentSlide = currentSlide > 0 ? currentSlide - 1 : slides.length - 1;
        scrollToSlide(currentSlide);
      });

      nextButton.addEventListener('click', () => {
        currentSlide = currentSlide < slides.length - 1 ? currentSlide + 1 : 0;
        scrollToSlide(currentSlide);
      });

      slider.addEventListener('scroll', () => {
        let activeSlide = 0;
        slides.forEach((slide, index) => {
          const slideLeftEdge = slide.getBoundingClientRect().left;
          const sliderLeftEdge = slider.getBoundingClientRect().left;
          if (
            Math.abs(slideLeftEdge - sliderLeftEdge) <
            slide.offsetWidth / 2
          ) {
            activeSlide = index;
          }
        });
        currentSlide = activeSlide;
        updateActiveSlide(currentSlide);
      });

      updateActiveSlide(currentSlide);
    });
  }

  // Helper functions
  getWidth(el, type) {
    if (type === 'inner')
      // .innerWidth()
      return el.clientWidth;
    if (type === 'outer')
      // .outerWidth()
      return el.offsetWidth;
    const s = window.getComputedStyle(el, null);
    if (type === 'width')
      // .width()
      return (
        el.clientWidth -
        parseInt(s.getPropertyValue('padding-left')) -
        parseInt(s.getPropertyValue('padding-right'))
      );
    if (type === 'full')
      // .outerWidth(includeMargins = true)
      return (
        el.offsetWidth +
        parseInt(s.getPropertyValue('margin-left')) +
        parseInt(s.getPropertyValue('margin-right'))
      );
    return null;
  }
}
